import React, {FC, useState} from 'react';
import {navigate} from '@reach/router';

import Section from '../../components/section';
import {Translate} from '../../components/translations';
import {PageHero} from '../../components/page-hero';
import MetaTagAnalyzerTool from '../../components/scan-tools/metaTagsAnalyzer';
import ToolToken from '../../components/scan-tools/ToolToken';
import ToolTabs from '../../components/scan-tools/ToolTabs';
import ScanCountChecker from '../../components/scan-tools/scan-counter';
import Layout from '../../components/layout/layout';
import {DomainForm} from '../../components/form/domain-form';
import Button from '../../components/button';

import metaTagAnalyzerImg from '../../img/home-tools/meta-tag-analyzer.png';
import hexomaticCTA from '../../img/hexomatic-banner/hexomatic-cta.jpg';

import {TrackToolScan} from '../../helpers/trackingEvents';
import {ScanResultsPageProps} from '../../types';

const MetaTagAnalyzerPage: FC<ScanResultsPageProps> = props => {
  const [scanLimitReached, setScanLimitReached] = useState(false);
  const url = props['*'];
  const hasDomain = url && url.length > 0;

  return (
    <Layout>
      <PageHero
        title={
          <Translate name={hasDomain ? 'META_TAG_ANALYZER_PAGE_TITLE' : 'SEO Meta tags scanner tool'} context={{url}} />
        }
        subtitle={
          hasDomain
            ? 'META_TAG_ANALYZER_PAGE_SUBTITLE'
            : 'Analyze the SEO titles and meta tags of any page using our free search engine optimization tool.'
        }
      >
        <DomainForm value={url} onSubmit={u => navigate('/pricing/')} />
      </PageHero>
      <Section>
        <div className="col-md-3">
          <ToolTabs active="meta-tag-analyzer" url={url} />
        </div>
        <div className="col-md-9 tool-detail">
          <div className="row">
            {hasDomain ? (
              <div className="col-md-12">
                <ScanCountChecker url={url}>
                  <ToolToken url={url || ''}>
                    {toolsSessionToken => {
                      TrackToolScan('MetaTagChecker', url || '');
                      return (
                        <div className="row">
                          <div className="col-md-12">
                            <MetaTagAnalyzerTool
                              url={url}
                              fullResult
                              sessionToken={toolsSessionToken}
                              handleScanLimitCheck={(limitReached: boolean) => setScanLimitReached(limitReached)}
                            />
                          </div>
                        </div>
                      );
                    }}
                  </ToolToken>
                </ScanCountChecker>
              </div>
            ) : (
              <>
                <div className="row">
                  <div className="col-md-4" style={{marginBottom: '20px'}}>
                    <img src={metaTagAnalyzerImg} alt="" style={{width: '100%', maxWidth: '300px'}} />
                  </div>
                  <div className="col-md-8">
                    <p>
                      Page title and meta description tags are used by search engines to determine information about
                      your page. Search engines also use these meta tags within search engine results pages and
                      optimizing these can lead to Click-through rate (CTR) and organic traffic.
                    </p>
                  </div>
                  <div className="col-md-12">
                    <p>
                      <strong>
                        Our free website SEO analyzer scans your website and checks for compliance with search engine
                        best practices and checks for:
                      </strong>
                    </p>
                    <ul>
                      <li>
                        Pages with missing <a href="https://hexometer.com/glossary/">Titles</a> and &nbsp;
                        <a href="https://hexometer.com/glossary/">Meta Descriptions</a>
                      </li>
                      <li>Duplicate Page Titles and Meta Descriptions</li>
                      <li>Short or Long Page Titles and Meta Descriptions</li>
                    </ul>
                  </div>
                </div>
                <div className="row" style={{marginTop: '30px'}}>
                  <div className="col-md-6" style={{margin: 'auto'}}>
                    <img src={hexomaticCTA} alt="Hexomatic" style={{width: '100%', maxWidth: '450px'}} />
                  </div>
                  <div className="col-md-6">
                    <div className="flex-align-center">
                      <h5 className="h5-title">Automate & scale time-consuming tasks like never before with Hexomatic</h5>
                      <p>
                        Harness the internet as your own data source, build your own scraping bots and leverage ready
                        made automations to delegate time consuming tasks and scale your business.
                      </p>
                      <p>No coding or PhD in programming required.</p>
                      <div>
                        <Button
                          hexomaticCTA
                          className="monitor_btn"
                          onClick={() =>
                            (window.location.href = 'https://hexomatic.com/?utm_source=hexometer&utm_medium=tools-cta')
                          }
                        >
                          Get started in minutes
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </Section>
    </Layout>
  );
};

export default MetaTagAnalyzerPage;
