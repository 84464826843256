import React, { FunctionComponent } from 'react';
import { ToolResultProps } from '../../../types';
import {META_TAGS_TOOL_SUBSCRIPTION} from "../../../graphql/subscriptions";
import FullResultCard from "./full-result";

import BaseToolWrap from '../BaseToolWrap';

const BrokenLinksTool: FunctionComponent<ToolResultProps> = ({ url, sessionToken, fullResult, onResult, handleScanLimitCheck}) => {
  return (
    <BaseToolWrap
      query={META_TAGS_TOOL_SUBSCRIPTION}
      FullResult={FullResultCard}
      url={url}
      sessionToken={sessionToken}
      fullResult={fullResult}
      onResult={onResult}
      handleScanLimitCheck={handleScanLimitCheck}
    />
  );
};

export default BrokenLinksTool;
