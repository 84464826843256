import React, { FC, useState, useEffect } from "react";
import {
  BrokenLinksQueryResultProps,
  BrokenLinkResultType,
  IStringMap,
  IGroupLinksByGroups,
  IStringMapAny,
} from "../../../types";
import { Translate } from "../../translations";
import PieChartMetrics from "../../pie-chart-metrics";
import { ResultCard } from "../../result-card";
import { Accordion, AccordionItem } from "../../accordion";
import { ProgressBar } from "../../progressBar";

const CHART_GROUPS: IStringMapAny = {
  WRONG_TITLES: {
    name: "Titles to Update",
    color: "#d35400",
    subGroups: {
      LONG_TITLE: "Page Title is too long (more than 60 characters)",
      DUPLICATE_TITLES: "Found duplicate titles",
    },
  },
  WRONG_DESCRIPTIONS: {
    name: "Descriptions to Update",
    color: "#e67e22",
    subGroups: {
      SHORT_DESCRIPTION:
        "Page Description is too short (less than 50 characters)",
      LONG_DESCRIPTION:
        "Page Description is too long (more than 160 characters)",
      DUPLICATE_DESCRIPTIONS: "Found duplicate descriptions",
    },
  },
  MISSING_TITLES: {
    name: "Missing Titles",
    color: "#273c75",
    subGroups: {
      NO_TITLE: "No Page Title is defined",
    },
  },
  MISSING_DESCRIPTIONS: {
    name: "Missing Descriptions",
    color: "#353b48",
    subGroups: {
      NO_DESCRIPTION: "No Page Meta Description is defined",
    },
  },
  META_TAGS_ARE_OK: {
    name: "Descriptions and Titles are OK",
    color: "#1abc9c",
    subGroups: {
      TITLE_DESCRIPTION_OK: "Checked Titles and Descriptions without issues",
    },
  },
};

const MetaTagCheckerTool: FC<BrokenLinksQueryResultProps> = ({
  url,
  baseUrl,
  links: linksSource,
  completed,
  _error,
}) => {
  const [stateLinks, setStateLinks] = useState(linksSource || []);

  useEffect(() => {
    if (url !== baseUrl) {
      setStateLinks([]);
    } else if (linksSource) {
      const linksFiltered = linksSource.filter(
        l =>
          l.internal && l.contentType && l.contentType.indexOf("text/html") > -1
      );
      if (!completed) {
        setStateLinks(stateLinks.concat(linksFiltered));
      } else {
        setStateLinks(linksFiltered);
      }
    }
  }, [linksSource, completed, url, baseUrl]);

  const sectionGroups: IGroupLinksByGroups = {};
  const links: BrokenLinkResultType[] = [];

  const setSG = (
    groupName: string,
    title: string,
    value: BrokenLinkResultType
  ) => {
    if (!sectionGroups[groupName]) {
      sectionGroups[groupName] = {};
    }

    if (!sectionGroups[groupName][title]) {
      sectionGroups[groupName][title] = [value];
    } else {
      sectionGroups[groupName][title].push(value);
    }
  };

  for (let index = 0; index < stateLinks.length; index++) {
    const l: BrokenLinkResultType = stateLinks[index];
    links.push(l);
    let hasError = false;

    if (!l.title || l.title.length == 0) {
      setSG(
        CHART_GROUPS.MISSING_TITLES.name,
        CHART_GROUPS.MISSING_TITLES.subGroups.NO_TITLE,
        l
      );
      hasError = true;
    } else if (l.title && l.title.length > 60) {
      setSG(
        CHART_GROUPS.WRONG_TITLES.name,
        CHART_GROUPS.WRONG_TITLES.subGroups.LONG_TITLE,
        l
      );
      hasError = true;
    }

    if (!l.metaTags || !l.metaTags.description) {
      setSG(
        CHART_GROUPS.MISSING_DESCRIPTIONS.name,
        CHART_GROUPS.MISSING_DESCRIPTIONS.subGroups.NO_DESCRIPTION,
        l
      );
      hasError = true;
    } else if (
      l.metaTags &&
      l.metaTags.description &&
      l.metaTags.description.length < 50
    ) {
      setSG(
        CHART_GROUPS.WRONG_DESCRIPTIONS.name,
        CHART_GROUPS.WRONG_DESCRIPTIONS.subGroups.SHORT_DESCRIPTION,
        l
      );
      hasError = true;
    } else if (
      l.metaTags &&
      l.metaTags.description &&
      l.metaTags.description.length > 160
    ) {
      setSG(
        CHART_GROUPS.WRONG_DESCRIPTIONS.name,
        CHART_GROUPS.WRONG_DESCRIPTIONS.subGroups.LONG_DESCRIPTION,
        l
      );
      hasError = true;
    }

    if (!hasError) {
      setSG(
        CHART_GROUPS.META_TAGS_ARE_OK.name,
        CHART_GROUPS.META_TAGS_ARE_OK.subGroups.TITLE_DESCRIPTION_OK,
        l
      );
    }
  }

  const chartGrouping = meta_tags_chart_data(links, sectionGroups);

  return (
    <>
      {!completed && !_error && (
        <div className="text-center">
          <div className="text-center">
            <ProgressBar />
          </div>
          <div className="text-center">
            <Translate name="TOOL_FULL_LOADING_TEXT" />
          </div>
        </div>
      )}

      {url && (
        <div className="row">
          <div className="col-md-12 text-right">
            <PieChartMetrics
              chartData={chartGrouping.chartData}
              labels={[
                {
                  text: "TOTAL_SCANNED_PAGES_NUMBER",
                  value: (links ? links.length : 0).toString(),
                },
                ...Object.keys(chartGrouping.linkTypes).map((lt: string) => {
                  return {
                    text: lt,
                    value: chartGrouping.linkTypes[lt],
                    color: chartGrouping.linkColors[lt],
                  };
                }),
              ]}
            />
          </div>
          <p>&nbsp;</p>
          <div className="col-md-12">
            {Object.keys(sectionGroups).map(rsTitle => (
              <ResultCard
                key={rsTitle}
                title={rsTitle}
                items={[
                  {
                    description: (
                      <Accordion>
                        {Object.keys(sectionGroups[rsTitle]).map(title => (
                          <AccordionItem
                            key={title}
                            title={`${title} (${sectionGroups[rsTitle][title].length})`}
                          >
                            {sectionGroups[rsTitle][title].map(
                              (link, index) => (
                                <p key={index}>
                                  <a href={link.url} target="_blank" rel='noopener'>
                                    {link.url}
                                  </a>
                                </p>
                              )
                            )}
                          </AccordionItem>
                        ))}
                      </Accordion>
                    ),
                  },
                ]}
              />
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export function isSuccessTitle(link: BrokenLinkResultType) {
  return link.title && link.title.length > 0 && link.title.length < 60;
}

export function isSuccessDescription(link: BrokenLinkResultType) {
  const description = link.metaTags && link.metaTags.description;
  if (!description) return false;
  return description.length > 50 && description.length < 160;
}

export function isSuccessMeta(link: BrokenLinkResultType) {
  return isSuccessTitle(link) && isSuccessDescription(link);
}

export function meta_tags_chart_data(
  links?: BrokenLinkResultType[],
  sectionGroups?: IGroupLinksByGroups
) {
  if (!links || !sectionGroups)
    return {
      chartData: [],
      linkTypes: {},
      linkColors: {},
    };
  const linkTypes: any = {};
  const linkColors: IStringMap = {};
  Object.keys(sectionGroups).map((chartTitle: string) => {
    linkTypes[chartTitle] = 0;
    linkColors[chartTitle] = Object.values(CHART_GROUPS).find(
      v => v.name === chartTitle
    ).color;
    Object.values(sectionGroups[chartTitle]).map(t => {
      linkTypes[chartTitle] += t.length;
    });
  });

  return {
    chartData: Object.keys(linkTypes).map(k => ({
      name: `${k} (${linkTypes[k]})`,
      value: linkTypes[k],
      fill: linkColors[k],
    })),
    linkTypes,
    linkColors,
  };
}

export default MetaTagCheckerTool;
